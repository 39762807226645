export const COMPONENT_SIZES = {
  xSmall: 'xs',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  xLarge: 'xlg',
  xxLarge: 'xxlg',
};

export const PARTNER_IMAGES = {
  PMI: `${process.env.VUE_APP_CDN_BASE_URL}/Files/Partners/pmi.png`,
};

export const SANAL_POS_URL = {
  garanti: 'https://sanalposprovtest.garanti.com.tr/servlet/gt3dengine',
};

export const COMPONENT_SHAPES = {
  rounded: 'rounded',
  circle: 'circle',
  rectangle: 'rectangle',
  square: 'square',
};

export const ICON_VARIABLES = {
  share: {
    name: 'IconShare',
    width: 18,
    height: 18,
  },
  search: {
    name: 'IconSearch',
    width: 20,
    height: 20,
  },
  filter: {
    name: 'IconFilter',
    width: 18,
    height: 19,
  },
  chefMale: {
    name: 'IconChefMale',
    width: 26,
    height: 28,
  },
  headphoneChef: {
    name: 'IconHeadphoneChef',
    width: 26,
    height: 26,
  },
  speechBubble: {
    name: 'IconSpeechBubble',
    width: 16,
    height: 17,
  },
  sheetLines: {
    name: 'IconSheetLines',
    width: 15,
    height: 17,
  },
  camera: {
    name: 'IconCamera',
    width: 16,
    height: 16,
  },
  cameraBig: {
    name: 'IconCamera',
    width: 20,
    height: 20,
  },
  cup: {
    name: 'IconCup',
    width: 19,
    height: 20,
  },
  cross: {
    name: 'IconCross',
    width: 20,
    height: 20,
  },
  wave: {
    name: 'IconWave',
    width: 36,
    height: 12,
  },
  waveHome: {
    name: 'IconWaveHome',
    width: 21,
    height: 8,
  },
  briefcase: {
    name: 'IconBriefcase',
    width: 21,
    height: 21,
  },
  wallet: {
    name: 'IconWallet',
    width: 21,
    height: 21,
  },
  pieChart: {
    name: 'IconPieChart',
    width: 21,
    height: 21,
  },
  magicStick: {
    name: 'IconMagicStick',
    width: 21,
    height: 21,
  },
  userGuide: {
    name: 'IconUserGuide',
    width: 21,
    height: 21,
  },
  tag: {
    name: 'IconTag',
    width: 21,
    height: 21,
  },
  info: {
    name: 'IconInfo',
    width: 21,
    height: 21,
  },
  cog: {
    name: 'IconCog',
    width: 21,
    height: 21,
  },
  checked: {
    name: 'IconCheck',
    width: 20,
    height: 20,
  },
  mobile: {
    name: 'IconMobile',
    width: 12,
    height: 16,
  },
  store: {
    name: 'IconStore',
    width: 14,
    height: 16,
  },
  envelop: {
    name: 'IconEnvelop',
    width: 16,
    height: 12,
  },
  chevronDown: {
    name: 'IconChevronDown',
    width: 10,
    height: 6,
  },
  expand: {
    name: 'IconExpand',
    width: 16,
    height: 14,
  },
  truck: {
    name: 'IconTruck',
    width: 14,
    height: 13,
  },
  eye: {
    name: 'IconEye',
    width: 23,
    height: 13,
  },
  layout: {
    name: 'IconLayout',
    width: 16,
    height: 16,
  },
  rememberMe: {
    name: 'IconRememberMe',
    width: 21,
    height: 21,
  },
  onOff: {
    name: 'IconOnOff',
    width: 16,
    height: 16,
  },
  chevronDownAlt: {
    name: 'IconChevronDownAlt',
    width: 18,
    height: 18,
  },
  chevronUpAlt: {
    name: 'IconChevronUpAlt',
    width: 18,
    height: 18,
  },
  checkedAlt: {
    name: 'IconCheckedAlt',
    width: 18,
    height: 18,
  },
  refresh: {
    name: 'IconRefresh',
    width: 20,
    height: 19,
  },
  phoneAlt: {
    name: 'IconPhoneAlt',
    width: 15,
    height: 15,
  },
  caretRight: {
    name: 'IconCaretRight',
    width: 5,
    height: 7,
  },
  right: {
    name: 'IconRight',
    width: 13,
    height: 18,
  },
  arrowLeft: {
    name: 'IconArrowLeft',
    width: 25,
    height: 12,
  },
  leftArrow: {
    name: 'IconLeftArrow',
    width: 13,
    height: 20,
  },
  rightArrow: {
    name: 'IconRightArrow',
    width: 13,
    height: 20,
  },
  circle: {
    name: 'IconCircle',
    width: 21,
    height: 21,
  },

  questionMark: {
    name: 'IconQuestionMark',
    width: 22,
    height: 22,
  },
  speechBubbleAlt: {
    name: 'IconSpeechBubbleAlt',
    width: 24,
    height: 24,
  },
  sheetTable: {
    name: 'IconSheetTable',
    width: 18,
    height: 24,
  },
  priceList: {
    name: 'IconPriceList',
    width: 22,
    height: 26,
  },
  productList: {
    name: 'IconProductList',
    width: 24,
    height: 25,
  },
  priceListHome: {
    name: 'IconPriceListHome',
    width: 15,
    height: 17,
  },
  packages: {
    name: 'IconPackages',
    width: 21,
    height: 21,
  },
  gavel: {
    name: 'IconGavel',
    width: 30,
    height: 30,
  },
  earth: {
    name: 'IconEarth',
    width: 30,
    height: 30,
  },
  home: {
    name: 'IconHome',
    width: 20,
    height: 20,
  },
  justifyRight: {
    name: 'IconJustifyRight',
    width: 20,
    height: 14,
  },
  humanFace: {
    name: 'IconHumanFace',
    width: 19,
    height: 23,
  },
  tick: {
    name: 'IconTick',
    width: 9,
    height: 6,
  },
  hearth: {
    name: 'IconHearth',
    width: 23,
    height: 20,
  },
  hearthNull: {
    name: 'IconHearthNull',
    width: 23,
    height: 20,
  },
  cart: {
    name: 'IconCart',
    width: 50,
    height: 50,
  },
  eorder: {
    name: 'IconEorder',
    width: 26,
    height: 24,
  },
  apply: {
    name: 'IconApply',
    width: 40,
    height: 40,
  },
  error: {
    name: 'IconError',
    width: 40,
    height: 40,
  },
  warn: {
    name: 'IconWarn',
    width: 40,
    height: 40,
  },
  detail: {
    name: 'IconDetail',
    width: 18,
    height: 18,
  },
  discount: {
    name: 'IconDiscount',
    width: 13,
    height: 16,
  },
  creditCard: {
    name: 'IconCreditCard',
    width: 18,
    height: 13,
  },
  faceSmile: {
    name: 'IconFaceSmile',
    width: 16,
    height: 16,
  },
  faceSad: {
    name: 'IconFaceSad',
    width: 16,
    height: 16,
  },
  points: {
    name: 'IconPoints',
    width: 22,
    height: 30,
  },
  print: {
    name: 'IconPrint',
    width: 22,
    height: 26,
  },
  personRight: {
    name: 'IconPersonRight',
    width: 18,
    height: 25,
  },
  personLeft: {
    name: 'IconPersonLeft',
    width: 18,
    height: 25,
  },
  trash: {
    name: 'IconTrash',
    width: 20,
    height: 24,
  },
  infoSmall: {
    name: 'IconInfoSmall',
    width: 14,
    height: 14,
  },
  time: {
    name: 'IconTime',
    width: 55,
    height: 76,
  },
  success: {
    name: 'IconSuccess',
    width: 105,
    height: 104,
  },
  fail: {
    name: 'IconFail',
    width: 105,
    height: 104,
  },
  iconChecked: {
    name: 'IconChecked',
    width: 53,
    height: 50,
  },
  like: {
    id: 1,
    name: 'IconLike',
    width: 34,
    height: 34,
  },
  likeNull: {
    id: 0,
    name: 'IconLikeNull',
    width: 34,
    height: 34,
  },
  hearthShapeEyes: {
    id: 2,
    name: 'IconHearthShapeEyes',
    width: 34,
    height: 34,
  },
  laughFace: {
    id: 3,
    name: 'IconLaughFace',
    width: 40,
    height: 34,
  },
  openMouthFace: {
    id: 4,
    name: 'IconOpenMouthFace',
    width: 34,
    height: 34,
  },
  sadFace: {
    id: 5,
    name: 'IconSadFace',
    width: 34,
    height: 34,
  },
  angryFace: {
    id: 6,
    name: 'IconAngryFace',
    width: 34,
    height: 34,
  },
  contact: {
    id: 7,
    name: 'IconContact',
    width: 21,
    height: 21,
  },
  otp: {
    id: 8,
    name: 'IconOtp',
    width: 18,
    height: 24,
  },
  virtualVisit: {
    name: 'IconVirtualVisit',
    width: 28,
    height: 29,
  },
  virtualVisitMenu: {
    name: 'IconVirtualVisit',
    width: 22,
    height: 21,
  },
  iconAgreement: {
    name: 'IconAgreement',
    width: 21,
    height: 21,
  },
  iconBeyondTime: {
    name: 'IconBeyondTime',
    width: 24,
    height: 24,
  },
  iconEqual: {
    name: 'IconEqual',
    width: 20,
    height: 20,
  },
  iconTrendyol: {
    name: 'IconTrendyol',
    width: 21,
    height: 21,
  },
  iconSurvey: {
    name: 'IconSurvey',
    width: 24,
    height: 24,
  },
  iconAktifPlusKey: {
    name: 'IconAktifPlusKey',
    width: 58,
    height: 58,
  },
  iconCircleCheck: {
    name: 'IconCircleCheck',
    width: 24,
    height: 24,
  },
  iconCircleCross: {
    name: 'IconCircleCross',
    width: 24,
    height: 24,
  },
  iconNotification: {
    name: 'IconNotification',
    width: 32,
    height: 32,
  },
  iconPointDetail: {
    name: 'IconPointDetail.',
    width: 34,
    height: 34,
  },
  moonStarOutline: {
    name: 'IconMoonStarOutline',
    width: 24,
    height: 24,
  },
  moonStarSolid: {
    name: 'IconMoonStarSolid',
    width: 24,
    height: 24,
  },
  sunRise: {
    name: 'IconSunRise',
    width: 24,
    height: 24,
  },
  sun: {
    name: 'IconSun',
    width: 24,
    height: 24,
  },
  sunHorizon: {
    name: 'IconSunHorizon',
    width: 24,
    height: 24,
  },
  sunSet: {
    name: 'IconSunSet',
    width: 24,
    height: 24,
  },
  moon: {
    name: 'IconMoon',
    width: 24,
    height: 24,
  },
  ramadanLanterns: {
    name: 'IconRamadanLanterns',
    width: 48,
    height: 48,
  },
};

export const VIDEO_LOG_STATUS = {
  viewed: '2',
  played: '1',
  default: '0',
};

export const SERVICE_URLS = {
  public: [
    '/account/loggedin/customer',
    '/account/loggedin/phone',
    '/account/loggedin/vkn',
    '/account/loggedin/pmtep',
    '/account/password',
    '/account/smscode',
    '/account/selectedpos',
  ],
};

export const ACCOUNT_TYPES = {
  default: 'default',
  kacc: 'KACC',
  lamp: 'LAMP',
  gt: 'GT',
};

export default {
  COMPONENT_SIZES,
  ICON_VARIABLES,
};
