import { HttpService } from '@/services/HttpService';

class JourneyAction {
  subdomain = '/journeyActionLog';

  /**
   * Log journey actions like views and clicks
   * @param {Object} logData - The data to be logged
   * @param {number} logData.journeyItemId - The ID of the journey item
   * @param {number} logData.journeyItemType - The type of the journey item (main banner, sub banner, popup)
   * @param {number} logData.actionType - The type of action (view, click)
   * @returns {Promise}
   */
  journeyActionLog(logData) {
    return HttpService.post(`${this.subdomain}`, logData);
  }
}

export default new JourneyAction();
