import { HttpService } from '@/services/HttpService';
class Common {
  subdomain = '/common';
  /**
   * Get all cities
   * @returns {Promise}
   */
  getCities() {
    return HttpService.get(`${this.subdomain}/city`);
  }
  /**
   * Get feed by feed id
   * @param {number} {Required} feedId
   *
   */
  getFeedById(feedId) {
    return HttpService.get(`${this.subdomain}/feeds/${feedId}`);
  }
  /**
   * Get feedsby feed id
   * @returns {Promise}
   */

  getFeeds(startIndex, pageSize, contentTypeId) {
    let queryString = `?startindex=${startIndex}&pagesize=${pageSize}&contenttypeId=${contentTypeId}`;
    return HttpService.get(`${this.subdomain}/feeds${queryString}`);
  }
  /**
   * Get menu
   * @returns {Promise}
   */
  getMenu() {
    return HttpService.get(`${this.subdomain}/menu`);
  }

  /**
   * Get sidebar slider data
   * @returns {Promise}
   */
  getPartnerBanners(customerCode) {
    return HttpService.get(`${this.subdomain}/partnerbanners?CustomerCode=${customerCode}`);
  }

  /**
   * Get weather data
   * @returns {Promise}
   */
  getWeather() {
    return HttpService.get(`${this.subdomain}/weather`);
  }

  /**
   * Get imsakiye data
   * @returns {Promise}
   */
  getImsakiye() {
    return HttpService.get(`${this.subdomain}/imsakiye`);
  }

  getFunBanners() {
    return HttpService.get(`${this.subdomain}/funsliderbanners`);
  }

  /**
   * Get get home page main slider data
   * @returns {Promise}
   */
  getMainBanners() {
    return HttpService.get(`${this.subdomain}/mainsliderbanners`);
  }
  /**
   * Get giftCatalog url
   * Get get Ecommerce Url data
   * @returns {Promise}
   */
  getEcommerceUrl() {
    return HttpService.get(`${this.subdomain}/ecommerceurl`);
  }
  /**
   * Get get Gift Catalog Link data
   * @returns {Promise}
   */
  getGiftCatalogLink() {
    return HttpService.get(`${this.subdomain}/giftcataloglink`);
  }
  /**
   * Get all genders
   * @returns {Promise}
   */
  getGenders() {
    return HttpService.get(`${this.subdomain}/gender`);
  }

  getCookieDetails() {
    return HttpService.get(`${this.subdomain}/getcookiedetails`);
  }

  getRSSList() {
    return HttpService.get(`${this.subdomain}/rsslist`);
  }

  /**
   * Contact
   * @param {number} {Required} subject
   * @param {number} {Required} message
   * @returns {Promise}
   */
  contact(data) {
    return HttpService.post(`/contactus`, data);
  }

  sendMail(data) {
    return HttpService.post(`/sendMail`, data);
  }
  /**
   * Get multi banner data
   * @returns {Promise}
   */
  getMultiBannerData() {
    return HttpService.get(`${this.subdomain}/mainslidermultibanners`);
  }
  getPopups(afterLogin) {
    return HttpService.get(`${this.subdomain}/popup?afterLogin=${afterLogin}`);
  }

  viewPopup(request) {
    return HttpService.post(`${this.subdomain}/viewpopup`, request);
  }

  getEmergencyTrainings() {
    return HttpService.get(`${this.subdomain}/videourl`);
  }

  getMarketingVideoSeries() {
    return HttpService.get(`${this.subdomain}/marketingvideourl`);
  }

  getNpsSurvey() {
    return HttpService.get(`${this.subdomain}/npssurvey`);
  }

  getNpsRosetteDetail() {
    return HttpService.get(`/evalutionsurvey/npsRozette`);
  }

  /**
   * Get feedback platform banner info
   * @returns {Promise}
   */
  getFeedbackPlatformBannerInfo() {
    return HttpService.get(`${this.subdomain}/feedbackplatformbannerinfo`);
  }
}
export default new Common();
